$(function () {
    if ($(".slider-for").length > 0) {
        $(".slider-for").slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            fade: true,
            asNavFor: ".slider-nav",
            rtl: $("body").hasClass("rtl") ? true : false,
        });

        $(".slider-nav").slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            asNavFor: ".slider-for",
            dots: false,
            focusOnSelect: true,
            rtl: $("body").hasClass("rtl") ? true : false,
        });
    }

    $(".btn-new-slide").on("click", function () {
        let index = $(this).attr("data-slide");

        let input_file =
            '<input class="d-none" type="file" accept=".jpeg,.jpg,.png" value="' +
            index +
            '" name="images[]" id="image_' +
            index +
            '">';

        let default_img =
            '<img src="' +
            location.origin +
            '/images/goods-dummy.jpg" class="w-100 rounded" alt="image">';


        $(".slider-for").slick(
            "slickAdd",
            '<div class="slick-slide-item">' + default_img + "</div>"
        );

        $(".slider-nav").slick(
            "slickAdd",
            '<div class="slick-slide-item mx-2">' +
                input_file +
                default_img +
                "</div>"
        );
        index++;
        $(this).attr("data-slide", index);
    });
    async function getDeletedID(index) {
        let all_delete_id = $("#delete_slide").val();
        let current_id = $('.slick-current>input[name="images[]"]').data("id");
        all_delete_id = all_delete_id ? JSON.parse(atob(all_delete_id)) : [];
        if (current_id) {
            all_delete_id.push(current_id);
        }
        $("#delete_slide").val(btoa(JSON.stringify(all_delete_id)));
        return index;
    }
    $(".btn-remove-slide").on("click", async function () {
        let currentSlide = $(".slider-for").slick("slickCurrentSlide");
        if ($("#delete_slide").length > 0) {
            getDeletedID(currentSlide).then(function (index) {
                $(".slider-for").slick("slickRemove", index);
                $(".slider-nav").slick("slickRemove", index);
            });
        } else {
            $(".slider-for").slick("slickRemove", currentSlide);
            $(".slider-nav").slick("slickRemove", currentSlide);
        }
    });
    $(".btn-upload-slide").on("click", function () {
        $('.slick-current>input[name="images[]"]').trigger("click");
    });

    $(document).on("change", 'input[name="images[]"]', function (e) {
        let temp_file = e.target.files[0]
            ? URL.createObjectURL(e.target.files[0])
            : null;
        if (temp_file) {
            $(".slick-current>img").attr("src", temp_file);
        }
    });
});
