require("./goods_details");
require("./chart");
require("./calender");
const lang = $("html").attr("lang");
const dictionaries = {
    yes: {
        en: "Yes",
        id: "Ya",
    },
    no: {
        en: "No",
        id: "Tidak",
    },
    loading: {
        en: "Loading",
        id: "Memuat",
    },
    balance: {
        en: "Balance",
        id: "Saldo",
    },
    select: {
        en: "Select",
        id: "Pilih",
    },
    empty: {
        en: "Empty",
        id: "Kosong",
    },
    save: {
        en: "Save",
        id: "Simpan",
    },
    prev: {
        en: "Previous",
        id: "Sebelumnya",
    },
    next: {
        en: "Next",
        id: "Selanjutnya",
    },
    submit: {
        en: "Submit",
        id: "Kirim",
    },
    out_stock: {
        en: "Out of Stock",
        id: "Stok Habis",
    },
    wo_numb: {
        en: "Input Work Order (WO) Number",
        id: "Masukkan Nomor Work Order (WO)",
    },
    cancel: {
        en: "Cancel",
        id: "Batal",
    },

    confirm: {
        save: {
            en: "Are you sure you want to save this data ?",
            id: "Apakah Anda yakin ingin menyimpan data ini ?",
        },
        submit: {
            en: "Are you sure you want to send this data ?",
            id: "Apakah Anda yakin ingin mengirimkan data ini ?",
        },
        print: {
            en: "Are you sure you want to print this data ?",
            id: "Apakah Anda yakin ingin mencetak data ini ?",
        },
        checkout: {
            en: "Are you sure you want to order all items in this cart ?",
            id: "Apakah Anda yakin akan memesan semua barang yang ada di keranjang ini ?",
        },
        default: {
            en: "Are you sure ?",
            id: "Apakah anda yakin ?",
        },
    },
    expired: {
        id: "Waktu Habis",
        en: "Time's up",
    },
    require: {
        goods: {
            en: "Please select goods first",
            id: "Silakan pilih barang terlebih dahulu",
        },
    },
    errors: {
        connection: {
            en: "Please check your internet connection",
            id: "Silakan periksa koneksi internet Anda",
        },
        popup: {
            en: "Please allow popups for this website",
            id: "Mohon diizinkan pop up pada website ini",
        },
    },
    success: {
        add_cart: {
            en: "Successfully added item to cart",
            id: "Berhasil menambahkan item ke keranjang",
        },
        print: {
            en: "Barcode data has been sent to the printer on your computer.",
            id: "Data barcode telah dikirim ke printer di komputer Anda.",
        },
    },
};
const theme = {
    orange: {
        color: "#ff6e40",
        hover: "#cc5833",
        smooth: "#faeeeb",
        shadow: "rgba(255, 132, 93, 0.5)",
    },
    red: {
        color: "#ef4444",
        hover: "#b91c1c",
        smooth: "#fbe9e9",
        shadow: "rgba(241, 91, 91, 0.5)",
    },
    sky: {
        color: "#0ea5e9",
        hover: "#0369a1",
        smooth: "#e7f9fe",
        shadow: "rgba(86, 195, 245, 0.5)",
    },
    blue: {
        color: "#292571",
        hover: "#453ec1",
        smooth: "#c7c5ec",
    },
};
$(document).ready(function () {
    if ($("body").data("theme")) {
        document.documentElement.style.setProperty(
            "--bs-primary",
            theme[$("body").data("theme")].color
        );
        document.documentElement.style.setProperty(
            "--bs-primary-hover",
            theme[$("body").data("theme")].hover
        );
        document.documentElement.style.setProperty(
            "--bs-primary-smooth",
            theme[$("body").data("theme")].smooth
        );
        document.documentElement.style.setProperty(
            "--bs-primary-shadow",
            theme[$("body").data("theme")].shadow
        );
    }
    $(".phone-number").mask("0000-0000-000000");
    $(".telp-number").mask("000-0000-00000");
    const base_url = window.location.origin;
    let submit = false;
    $(".form-confirm").on("submit", function (e) {
        let message =
            $(this).data("confirm") ?? dictionaries.confirm.default[lang];
        if (!submit) {
            e.preventDefault();
            Swal.fire({
                icon: "question",
                text: message,
                showDenyButton: true,
                confirmButtonText: dictionaries.yes[lang],
                denyButtonText: dictionaries.no[lang],
            }).then((result) => {
                if (result.isConfirmed) {
                    submit = true;
                    $(".preloader").show();
                    $(this).trigger("submit");
                }
            });
        }
    });

    $(".form-filter").on("change", function () {
        console.log($(this).closest("form"));
        $(this).closest("form").trigger("submit");
    });

    $(".btn-destroy").on("click", async function (e) {
        e.preventDefault();
        $("#form_destroy").attr("action", $(this).data("action"));
        $("#form_destroy").trigger("submit");
    });

    $(".btn-trigger").on("click", function (e) {
        e.preventDefault();
        let target_id = $(this).data("target");
        let action = $(this).data("action");
        $(target_id).trigger(action);
    });

    $("#photo").on("change", function (e) {
        let temp_file = e.target.files[0]
            ? URL.createObjectURL(e.target.files[0])
            : null;
        $("#photo_view").attr("src", temp_file);
    });
    $("#unit_id").on("change", function (e) {
        $("#dept_id").val("");
        $("#dept_id").html(
            '<option value="" disabled selected>' +
                dictionaries.loading[lang] +
                "...</option>"
        );
        postData(
            base_url + "/business-units/" + $(this).val() + "/departements",
            {},
            function (response) {
                let text_option =
                    response.length > 0
                        ? dictionaries.select[lang]
                        : dictionaries.empty[lang];

                $("#dept_id").html(
                    '<option value="" disabled selected>' +
                        text_option +
                        "</option>"
                );
                $.each(response, function (index, key) {
                    $("#dept_id").append(new Option(key.name, key.id));
                });
            }
        );
    });

    $("#prov_id").on("change", function (e) {
        $("#city_id").val("");
        $("#city_id").html(
            '<option value="" disabled selected>' +
                dictionaries.loading[lang] +
                "...</option>"
        );
        postData(
            base_url + "/cities",
            { prov_id: $(this).val() },
            function (response) {
                let text_option =
                    response.length > 0
                        ? dictionaries.select[lang]
                        : dictionaries.empty[lang];

                $("#city_id").html(
                    '<option value="" disabled selected>' +
                        text_option +
                        "</option>"
                );
                $.each(response, function (index, key) {
                    $("#city_id").append(new Option(key.name, key.id));
                });
            }
        );
    });

    $("#city_id").on("change", function (e) {
        $("#district_id").val("");
        $("#district_id").html(
            '<option value="" disabled selected>' +
                dictionaries.loading[lang] +
                "...</option>"
        );
        postData(
            base_url + "/districts",
            { city_id: $(this).val() },
            function (response) {
                let text_option =
                    response.length > 0
                        ? dictionaries.select[lang]
                        : dictionaries.empty[lang];

                $("#district_id").html(
                    '<option value="" disabled selected>' +
                        text_option +
                        "</option>"
                );
                $.each(response, function (index, key) {
                    $("#district_id").append(new Option(key.name, key.id));
                });
            }
        );
    });

    $("#district_id").on("change", function (e) {
        $("#subdistrict_id").val("");
        $("#subdistrict_id").html(
            '<option value="" disabled selected>' +
                dictionaries.loading[lang] +
                "...</option>"
        );
        postData(
            base_url + "/subdistricts",
            { district_id: $(this).val() },
            function (response) {
                let text_option =
                    response.length > 0
                        ? dictionaries.select[lang]
                        : dictionaries.empty[lang];

                $("#subdistrict_id").html(
                    '<option value="" disabled selected>' +
                        text_option +
                        "</option>"
                );
                $.each(response, function (index, key) {
                    $("#subdistrict_id").append(new Option(key.name, key.id));
                });
            }
        );
    });
    $("#wh_id").on("change", function () {
        if ($("#storage_id").length > 0) {
            $("#storage_id").val("");
            $("#storage_id").html(
                '<option value="" disabled selected>' +
                    dictionaries.loading[lang] +
                    "...</option>"
            );
            postData(
                base_url + "/warehouses/" + $(this).val() + "/storages",
                { category_id: $(this).data("category") },
                function (response) {
                    let text_option =
                        response.length > 0
                            ? dictionaries.select[lang]
                            : dictionaries.empty[lang];

                    $("#storage_id").html(
                        '<option value="" disabled selected>' +
                            text_option +
                            "</option>"
                    );
                    $.each(response, function (index, key) {
                        $("#storage_id").append(new Option(key.name, key.id));
                    });
                }
            );
        }
    });
    $("#storage_category_id").on("change", function () {
        let value = $(this).val();
        if ($("#storage_id").length > 0) {
            $(".col-option").each(function (index, element) {
                $(element).prop("hidden", value != 1);
                $(element)
                    .find(".form-option")
                    .prop("required", value == 1);
            });
            $("#storage_id").val("");
            $("#storage_id").html(
                '<option value="" disabled selected>' +
                    dictionaries.loading[lang] +
                    "...</option>"
            );
            postData(
                base_url + "/storage-categories/" + value + "/storages",
                {},
                function (response) {
                    let text_option =
                        response.length > 0
                            ? dictionaries.select[lang]
                            : dictionaries.empty[lang];

                    $("#storage_id").html(
                        '<option value="" disabled selected>' +
                            text_option +
                            "</option>"
                    );
                    $.each(response, function (index, key) {
                        $("#storage_id").append(new Option(key.name, key.id));
                    });
                }
            );
        }
    });
    $("#storage_id").on("change", function () {
        if ($("#floor_id").length > 0) {
            $("#floor_id").val("");
            $("#floor_id").html(
                '<option value="" disabled selected>' +
                    dictionaries.loading[lang] +
                    "...</option>"
            );
            postData(
                base_url + "/storages/" + $(this).val() + "/floors",
                {},
                function (response) {
                    let text_option =
                        response.length > 0
                            ? dictionaries.select[lang]
                            : dictionaries.empty[lang];

                    $("#floor_id").html(
                        '<option value="" disabled selected>' +
                            text_option +
                            "</option>"
                    );
                    $.each(response, function (index, key) {
                        $("#floor_id").append(new Option(key.name, key.id));
                    });
                }
            );
        } else if ($("#floorname").length > 0) {
            $("#floorname").attr(
                "placeholder",
                dictionaries.loading[lang] + "..."
            );
            postData(
                base_url + "/storage-floors/get/sequence",
                {
                    floor_id: $("#id").val(),
                    storage_id: $("#storage_id").val(),
                },
                function (response) {
                    $("#floorname").val(response);
                }
            );
        }
    });
    $("#floor_id").on("change", function () {
        if ($("#cabin_id").length > 0) {
            $("#cabin_id").val("");
            $("#cabin_id").html(
                '<option value="" disabled selected>' +
                    dictionaries.loading[lang] +
                    "...</option>"
            );
            postData(
                base_url + "/storage-floors/" + $(this).val() + "/cabinets",
                {},
                function (response) {
                    let text_option =
                        response.length > 0
                            ? dictionaries.select[lang]
                            : dictionaries.empty[lang];

                    $("#cabin_id").html(
                        '<option value="" disabled selected>' +
                            text_option +
                            "</option>"
                    );
                    $.each(response, function (index, key) {
                        $("#cabin_id").append(new Option(key.name, key.id));
                    });
                }
            );
        } else if ($("#cabinname").length > 0) {
            $("#cabinname").attr(
                "placeholder",
                dictionaries.loading[lang] + "..."
            );
            postData(
                base_url + "/floor-cabinets/get/sequence",
                {
                    cabin_id: $("#id").val(),
                    floor_id: $("#floor_id").val(),
                },
                function (response) {
                    $("#cabinname").val(response);
                }
            );
        }
    });

    $("#cabin_id").on("change", function () {
        if ($("#rack_id").length > 0) {
            $("#rack_id").val("");
            $("#rack_id").html(
                '<option value="" disabled selected>' +
                    dictionaries.loading[lang] +
                    "...</option>"
            );
            postData(
                base_url + "/floor-cabinets/" + $(this).val() + "/racks",
                {},
                function (response) {
                    let text_option =
                        response.length > 0
                            ? dictionaries.select[lang]
                            : dictionaries.empty[lang];

                    $("#rack_id").html(
                        '<option value="" disabled selected>' +
                            text_option +
                            "</option>"
                    );
                    $.each(response, function (index, key) {
                        $("#rack_id").append(
                            new Option(key.name + key.size, key.id)
                        );
                    });
                }
            );
        } else if ($("#rackname").length > 0) {
            $("#rackname").attr(
                "placeholder",
                dictionaries.loading[lang] + "..."
            );
            postData(
                base_url + "/cabinet-racks/get/sequence",
                {
                    rack_id: $("#id").val(),
                    cabin_id: $("#cabin_id").val(),
                },
                function (response) {
                    $("#rackname").val(response);
                }
            );
        }
    });

    $("#rack_id").on("change", function () {
        if ($("#box_id").length > 0) {
            $("#box_id").val("");
            $("#box_id").html(
                '<option value="" disabled selected>' +
                    dictionaries.loading[lang] +
                    "...</option>"
            );
            postData(
                base_url + "/cabinet-racks/" + $(this).val() + "/boxes",
                { tallying_id: $(this).data("tallying") },
                function (response) {
                    let text_option =
                        response.length > 0
                            ? dictionaries.select[lang]
                            : dictionaries.empty[lang];

                    $("#box_id").html(
                        '<option value="" disabled selected>' +
                            text_option +
                            "</option>"
                    );
                    $.each(response, function (index, item) {
                        let available = item.capacity;
                        if (item.tallyings_sum_qty)
                            available -= parseInt(item.tallyings_sum_qty);
                        if (item.stock_outbounds_sum_qty)
                            available += parseInt(item.stock_outbounds_sum_qty);

                        if (available > 0) {
                            $("#box_id").append(
                                '<option value="' +
                                    item.id +
                                    '">' +
                                    item.name +
                                    " (" +
                                    available +
                                    ")" +
                                    "</option>"
                            );
                        }
                    });
                }
            );
        } else if ($("#boxname").length > 0) {
            $("#boxname").attr(
                "placeholder",
                dictionaries.loading[lang] + "..."
            );
            postData(
                base_url + "/rack-boxes/get/sequence",
                {
                    box_id: $("#id").val(),
                    rack_id: $("#rack_id").val(),
                },
                function (response) {
                    $("#boxname").val(response);
                }
            );
        }
    });

    $("input[name='is_virtual']").on("click", function () {
        let val = $(this).val();
        $(".col-option").each(function (index, element) {
            $(element).prop("hidden", val != 1);
            $(element)
                .find(".form-option")
                .prop("required", val == 1);
        });
    });
    $("#role_id").on("change", function () {
        let pengawas_penguji = $(this).val() == 9 || $(this).val() == 10;
        let user_dept = $(this).val() == 6;
        $(".col-option").each(function (index, element) {
            $(element).prop("hidden", !pengawas_penguji);
            $(element).find(".form-option").prop("required", pengawas_penguji);
        });
        $(".col-sd").prop("hidden", !user_dept);
        $("#supervisor_id").prop("required", user_dept);
    });

    if ($(".nav-item.open>.nav-link").length > 0) {
        $(".nav-item.open>.nav-link").addClass("active");
    }

    if ($("#submission-steps").length > 0) {
        let last_form =
            $("#form-submission-images").length > 0
                ? "#form-submission-images"
                : "#form-submission-docs";
        $("#submission-steps").steps({
            headerTag: "h3",
            bodyTag: "section",
            autoFocus: true,
            titleTemplate: '<span class="wizard-index">#index#</span> #title#',
            labels: {
                finish: dictionaries.save[lang],
                next: dictionaries.next[lang],
                previous: dictionaries.prev[lang],
            },
            onStepChanging: function (event, currentIndex, newIndex) {
                if (currentIndex <= newIndex) {
                    var form = document.getElementById("form-submission-info"),
                        form2 = document.getElementById("form-submission-docs"),
                        form3 = document.getElementById(
                            "form-submission-images"
                        );
                    console.log(currentIndex);
                    if (currentIndex === 0) {
                        if (form.checkValidity() === false) {
                            event.preventDefault();
                            event.stopPropagation();
                            form.classList.add("was-validated");
                        } else {
                            $("#form-submission-info")
                                .find(":input")
                                .clone()
                                .hide()
                                .attr("is-info", true)
                                .appendTo(last_form);

                            $("#form-submission-info select").each(function (
                                index,
                                element
                            ) {
                                $(
                                    last_form +
                                        ' select[name="' +
                                        $(element).attr("name") +
                                        '"]'
                                ).val($(element).val());
                            });
                            return true;
                        }
                    } else if (currentIndex === 1) {
                        if (form2.checkValidity() === false) {
                            event.preventDefault();
                            event.stopPropagation();
                            form2.classList.add("was-validated");
                        } else {
                            if (form3.length > 0) {
                                $("#form-submission-docs")
                                    .find(":input")
                                    .clone()
                                    .hide()
                                    .attr("is-docs", true)
                                    .appendTo(last_form);
                            }

                            return true;
                        }
                    } else {
                        if (form3.checkValidity() === false) {
                            event.preventDefault();
                            event.stopPropagation();
                            form3.classList.add("was-validated");
                        } else {
                        }
                    }
                } else {
                    $(last_form)
                        .find(":input")
                        .each(function (index, element) {
                            if (
                                (newIndex === 0 &&
                                    $(element).attr("is-info")) ||
                                (newIndex === 1 && $(element).attr("is-docs"))
                            ) {
                                $(element).remove();
                            }
                        });
                    return true;
                }
            },
            onFinished: function () {
                Swal.fire({
                    icon: "question",
                    text: dictionaries.confirm.save[lang],
                    showDenyButton: true,
                    confirmButtonText: dictionaries.yes[lang],
                    denyButtonText: dictionaries.no[lang],
                }).then((result) => {
                    if (result.isConfirmed) {
                        submit = true;
                        $(".preloader").show();
                        $(last_form).trigger("submit");
                    }
                });
            },
        });
    }
    if ($(".select-goods").length > 0) {
        $(".select-goods").select2({
            ajax: {
                headers: {
                    "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                        "content"
                    ),
                },
                delay: 250,
                method: "post",
                url: location.origin + "/search/goods",
                data: function (params) {
                    var query = {
                        search: params.term,
                        page: params.page || 1,
                        groups_id: $(this).data("groups"),
                        is_deletion: $(this).data("deletion"),
                    };
                    console.log(query);
                    // Query parameters will be ?search=[term]&type=public
                    return query;
                },
                processResults: function (data) {
                    return {
                        results: data.data,
                        pagination: {
                            more: data.current_page * 30 < data.total,
                        },
                    };
                },
            },
            minimumInputLength: 2,
        });
    }
    if ($(".select-users").length > 0) {
        $(".select-users").select2({
            ajax: {
                headers: {
                    "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                        "content"
                    ),
                },
                delay: 250,
                method: "post",
                url: location.origin + "/search/users",
                data: function (params) {
                    let dept_id = $(this).data("departements");
                    dept_id = dept_id ? dept_id.toString().split(",") : [];
                    var query = {
                        search: params.term,
                        page: params.page || 1,
                        dept_id: dept_id,
                    };
                    return query;
                },
                processResults: function (data) {
                    return {
                        results: data.data,
                        pagination: {
                            more: data.current_page * 30 < data.total,
                        },
                    };
                },
            },
            minimumInputLength: 2,
        });

        $(".select-users").on("select2:select", function (e) {
            let data = e.params.data;
            if ($("#role_id").length > 0) {
                $("#role_id").val("");
                $("#role_id").html(
                    '<option value="" disabled selected>' +
                        dictionaries.loading[lang] +
                        "...</option>"
                );
                postData(
                    base_url + "/users/" + data.nip + "/roles",
                    {},
                    function (response) {
                        let select_role =
                            response.roles.length > 0
                                ? dictionaries.select[lang]
                                : dictionaries.empty[lang];
                        let select_supervisor =
                            response.supervisors.length > 0
                                ? dictionaries.select[lang]
                                : dictionaries.empty[lang];

                        $("#role_id").html(
                            '<option value="" disabled selected>' +
                                select_role +
                                "</option>"
                        );
                        $("#supervisor_id").html(
                            '<option value="" disabled selected>' +
                                select_supervisor +
                                "</option>"
                        );
                        $.each(response.roles, function (index, key) {
                            $("#role_id").append(new Option(key.name, key.id));
                        });
                        $.each(response.supervisors, function (index, key) {
                            $("#supervisor_id").append(
                                new Option(key.name + " | " + key.nip, key.id)
                            );
                        });
                    }
                );
            }
        });
    }
    $(".btn-submit").on("click", function (e) {
        e.preventDefault();
        let message =
            $(this).data("confirm") ?? dictionaries.confirm.submit[lang];
        $($(this).data("target"))
            .attr("action", $(this).attr("href"))
            .attr("data-confirm", message)
            .trigger("submit");
    });
    $(".btn-modal-submit").on("click", function (e) {
        e.preventDefault();
        let label = $(this).data("label");
        let params = $(this).data("params");
        let message =
            $(this).data("confirm") ?? dictionaries.confirm.submit[lang];

        $($(this).data("form"))
            .attr("action", $(this).attr("href"))
            .attr("data-confirm", message);

        if (label) {
            $($(this).data("bs-target") + "Label").text(label);
        }
        params = params ? params.split(",") : [];
        $($(this).data("form") + " :input ").each(function (index, element) {
            let name = $(element).attr("name");
            if (name) {
                $(element).prop("required", false);
                $(element).prop("hidden", true);
                params.forEach((key) => {
                    if (name == key) {
                        $(element).prop(
                            "required",
                            $(element).data("option") ? false : true
                        );
                        $(element).prop("hidden", false);
                        if ($(element).data("parent")) {
                            $(element).parent().prop("hidden", false);
                        }
                    }
                });
            }
        });
    });
    $("#submission_id").on("change", function (e) {
        if ($("#sg_id").length > 0) {
            $("#sg_id").val("");
            $("#sg_id").html(
                '<option value="" disabled selected>' +
                    dictionaries.loading[lang] +
                    "...</option>"
            );
            if ($("#qty").length > 0) {
                $("#qty")
                    .prop("readonly", true)
                    .attr("placeholder", dictionaries.require.goods[lang]);
            }
            postData(
                base_url + "/submissions/" + $(this).val() + "/tallying-goods",
                { tallying_id: $("#tallying_id").val() ?? null },
                function (response) {
                    let text_option =
                        response.length > 0
                            ? dictionaries.select[lang]
                            : dictionaries.empty[lang];

                    $("#sg_id").html(
                        '<option value="" disabled selected>' +
                            text_option +
                            "</option>"
                    );
                    response.forEach((item) => {
                        let max = item.received_qty - item.tallyings_sum_qty;
                        if (max > 0) {
                            $("#sg_id").append(
                                '<option value="' +
                                    item.id +
                                    '" data-max="' +
                                    max +
                                    '">' +
                                    item.goods.code +
                                    " | " +
                                    item.goods.name +
                                    " (" +
                                    max +
                                    ")" +
                                    "</option>"
                            );
                        }
                    });
                }
            );
        }
    });
    $("#sg_id").on("change", function (e) {
        if ($("#qty").length > 0) {
            let max = $("#sg_id option:selected").attr("data-max");
            $("#qty").attr("max", max);
            $("#qty_max").text("( Max : " + max + " )");
            $("#qty").prop("readonly", false).attr("placeholder", "");
            $("#qty").removeAttr("readonly");
        }
    });
    $("#modal_deletion_confirm").on("show.bs.modal", function (e) {
        let data = $(e.relatedTarget).data("sg");
        data = data ? JSON.parse(atob(data)) : null;
        if (data) {
            $("#dpb").text(data.dpb);
            $("#good").text(data.good);
            $("#received_qty").text(data.received_qty);
            $("#qty").val(data.qty);
            $("#qty").attr("max", data.received_qty);
        }
    });
    $(".check-all-dashboard").change(function () {
        $(".check-dashboard").prop("checked", $(this).is(":checked"));
    });

    $(".check-dashboard").change(function () {
        let is_check =
            $(".check-dashboard:checked").length ==
            $(".check-dashboard").length;
        $(".check-all-dashboard").prop("checked", is_check);
    });
    $(document).on("click", ".select-all", function () {
        let that = $(this),
            target = $(that.data("select-all-target")),
            checkbox = target.find('input[type="checkbox"]');

        if (that.prop("checked")) {
            checkbox.closest("tr").addClass("tr-selected");
            checkbox.prop("checked", true);
        } else {
            checkbox.closest("tr").removeClass("tr-selected");
            checkbox.prop("checked", false);
        }
    });

    $(document).on("click", '#check_table input[type="checkbox"]', function () {
        let that = $(this);
        let check_all =
            $('#check_table input[name="table_checks[]"]:checked').length ==
            $('#check_table input[name="table_checks[]"]').length;
        $(".select-all").prop("checked", check_all);

        let is_check =
            $('#check_table input[name="table_checks[]"]:checked').length > 0;

        if (that.prop("checked")) {
            $("#btn_submit_check,#btn_delete_check").prop("disabled", false);
            $("#btn_submit_check,#btn_delete_check").removeClass("disabled");
            that.closest("tr").addClass("tr-selected");
        } else {
            if (!is_check) {
                $('#check_table input[type="checkbox"]').prop("checked", false);
                $("#btn_submit_check,#btn_delete_check").addClass("disabled");
            } else {
                $("#btn_submit_check,#btn_delete_check").removeClass(
                    "disabled"
                );
            }
            $("#btn_submit_check,#btn_delete_check").prop(
                "disabled",
                !is_check
            );
            that.closest("tr").removeClass("tr-selected");
        }
    });
    $("#check_all_order_table").change(function () {
        if ($(this).is(":checked")) {
            let status = $("#check_all_order_table").attr("data-status");
            let dept_id = $("#check_all_order_table").attr("data-dept");
            if (status && dept_id) {
                let disabled = true;
                $(".check-order").each(function (index, element) {
                    disabled = $(element).data("status") != status;
                    if (!disabled && status == 192)
                        disabled = $(element).data("dept") != dept_id;
                    $(element).prop("disabled", disabled);

                    $(element).prop("checked", !disabled);
                });
            } else {
                $(this).prop("checked", false);
            }
        } else {
            $(this).prop(
                "disabled",
                $('select[name="status_id"]').val() != null ||
                    $('select[name="status_id"]').val() != 192
            );
            $(".check-order").prop("checked", false);
        }
    });

    $(".check-order").change(function () {
        let status = $(this).data("status");
        let dept_id = $(this).data("dept");
        let checked_count = $(".check-order:checked:not(:disabled)").length;
        if (checked_count == 0) {
            $(".check-order").prop("disabled", false);
            $(".check-order").prop("checked", false);
            $("#check_status").val(null);
            $(".btn-action").addClass("disabled");
            $("#check_all_order_table").prop("disabled", true);
        } else {
            let disabled = true;

            $(".check-order").each(function (index, element) {
                disabled = $(element).data("status") != status;
                if (!disabled && status == 192)
                    disabled = $(element).data("dept") != dept_id;

                $(element).prop("disabled", disabled);

                if (disabled) $(element).prop("checked", false);
            });
            $("#check_status").val(status);
            $(".btn-action").each(function (index, element) {
                $(element).toggleClass(
                    "disabled",
                    $(element).data("status") != status
                );
            });
            $("#check_all_order_table").prop("disabled", false);
        }
        $("#check_all_order_table").attr("data-status", status);
        $("#check_all_order_table").attr("data-dept", dept_id);
        $("#check_all_order_table").prop(
            "checked",
            checked_count == $(".check-order:not(:disabled)").length
        );
    });
    $(".btn-alert").on("click", function (e) {
        e.preventDefault();
        Swal.fire(
            $(this).data("title"),
            $(this).data("message"),
            $(this).data("icon")
        );
    });
    $(".check-access").on("click", function () {
        postData(base_url + "/role-menus", {
            role_id: $("#role_id").val(),
            menu_id: $(this).val(),
            is_checked: $(this).is(":checked"),
        });
    });
    $(".check-permission").on("click", function () {
        postData(base_url + "/role-permissions", {
            role_id: $(this).data("role"),
            model: $(this).val(),
            action: $(this).data("action"),
            is_checked: $(this).is(":checked"),
        });
    });
    $("#is_semester").on("change", function () {
        let value = $(this).val() ?? 0;
        $(".col-option").each(function (index, element) {
            let opt = $(element).data("option") ?? 0;
            $(element).prop("hidden", value != opt);
            $(element)
                .find(".form-option")
                .prop("required", value == opt);
        });
    });
    $("#year_opname").on("change", function () {
        if ($("#semester").length > 0) {
            let year = new Date().getFullYear();
            let month = new Date().getMonth();
            let opt =
                '<option value="" selected disabled>' +
                dictionaries.select[lang] +
                "</option>";
            if (
                $(this).val() < year ||
                (year == $(this).val() && month == 12)
            ) {
                opt += '<option value="1" >1</option>';
                opt += '<option value="2" >2</option>';
            } else {
                opt += '<option value="1" selected>1</option>';
            }
            $("#semester").html(opt);
        }
    });
    $(".form-modal").on("show.bs.modal", function (e) {
        let modal = $(this);
        let button = $(e.relatedTarget);
        let value = button.data("val")
            ? JSON.parse(atob(button.data("val")))
            : null;
        let text = button.data("text")
            ? JSON.parse(atob(button.data("text")))
            : null;

        if (button.attr("href"))
            modal.closest("form").attr("action", button.attr("href"));

        if (value)
            $.each(value, function (key, item) {
                modal.find('[name="' + key + '"]').val(item);
            });

        if (text)
            $.each(text, function (key, item) {
                console.log(key);
                console.log(item);
                $(key).text(item);
            });
    });
    $(".order-timer").each(function (index, element) {
        countDownTimer($(element).data("duration"), $(element));
    });
    function countDownTimer(duration, element) {
        if (duration <= 0 || !duration) location.reload();

        let timer = duration,
            hours,
            minutes,
            seconds;
        setInterval(function () {
            hours = parseInt(timer / 60 / 60, 10);
            minutes = parseInt((timer / 60) % 60, 10);
            seconds = parseInt(timer % 60, 10);

            hours = hours < 10 ? "0" + hours : hours;
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            element.text(hours + ":" + minutes + ":" + seconds);

            if (--timer < 0) location.reload();
        }, 1000);
    }
    $("#fuel_id").on("change", function () {
        let max = $("#fuel_id option:selected").attr("data-balance");
        $("#volume").attr("max", max);
        $("#volume_max").text(
            "( " + dictionaries.balance[lang] + " : " + max + " )"
        );
        if (max > 0) {
            $("#volume").attr("placeholder", "");
            $("#volume").removeAttr("readonly");
        } else {
            $("#volume").prop("readonly", true);
            $("#volume").val("");
            $("#volume").attr("placeholder", dictionaries.out_stock[lang]);
        }
    });
    $(".btn-print-barcode").on("click", function (e) {
        e.preventDefault();
        let params = $(this).data("params");
        Swal.fire({
            icon: "question",
            text: dictionaries.confirm.print[lang],
            showDenyButton: true,
            confirmButtonText: dictionaries.yes[lang],
            denyButtonText: dictionaries.no[lang],
        }).then((result) => {
            if (result.isConfirmed) {
                var print_tab = window.open(
                    "http://localhost:8282/print/" + params,
                    "_blank"
                );
                if (print_tab) {
                    let interval = setInterval(function () {
                        print_tab.close();
                        Swal.fire({
                            text: dictionaries.success.print[lang],
                            icon: "success",
                        });
                        clearInterval(interval);
                    }, 5000);
                } else {
                    Swal.fire(
                        "Gagal",
                        dictionaries.errors.popup[lang],
                        "warning"
                    );
                }
            }
        });
    });
    $(".btn-add-cart").on("click", function () {
        $(this).html(
            '<div class="spinner-border text-light spinner-border-sm" role="status"><span class="visually-hidden">Loading...</span></div>'
        );
        $(this).addClass("disabled");
    });
    $(".btn-minus-cart").on("click", function (e) {
        e.preventDefault();
        let target = $($(this).data("target"));
        let curr_val = parseInt(target.val());
        if (curr_val > 1) {
            target.val(curr_val - 1).trigger("change");
        }
    });
    $(".btn-plus-cart").on("click", function (e) {
        e.preventDefault();
        let target = $($(this).data("target"));
        let max = parseInt(target.attr("max"));
        let curr_val = parseInt(target.val());
        if (target.length > 0 && max > curr_val) {
            target.val(curr_val + 1).trigger("change");
        }
    });
    $("#btn-checkout-all").on("click", function (e) {
        Swal.fire({
            title: dictionaries.wo_numb[lang],
            text: "(Optional)",
            input: "text",
            inputAttributes: {
                maxlength: "50",
                autocapitalize: "off",
            },
            showDenyButton: true,
            confirmButtonText: dictionaries.submit[lang],
            denyButtonText: dictionaries.cancel[lang],
            allowOutsideClick: () => !Swal.isLoading(),
            preConfirm: (value) => {
                $('#input_wo_topbar').val(value);
            },
        }).then((result) => {
            console.log(result);
            if (result.isConfirmed) {
                $("#form_checkout_topbar").trigger("submit");
            }
        });
    });
    $(".btn-submit-wo").on("click", function (e) {
        e.preventDefault();
        Swal.fire({
            title: dictionaries.wo_numb[lang],
            text: "(Optional)",
            input: "text",
            inputAttributes: {
                maxlength: "50",
                autocapitalize: "off",
            },
            showDenyButton: true,
            confirmButtonText: dictionaries.submit[lang],
            denyButtonText: dictionaries.cancel[lang],
            preConfirm: async (value) => {
                $("#wo_numb").val(value);
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            let message =
                $(this).data("confirm") ?? dictionaries.confirm.submit[lang];
            if (result.isConfirmed) {
                $($(this).data("target"))
                    .attr("action", $(this).attr("href"))
                    .attr("data-confirm", message)
                    .trigger("submit");
            }
        });
    });
    $(".cart-qty-input").on("change", function (e) {
        let input = $(this);
        $("#status_" + input.data("id")).html(
            '<div class="spinner-border text-muted spinner-border-sm " role="status"><span class="visually-hidden">Loading...</span></div> <span class="text-muted">Loading...</span>'
        );
        $.ajax({
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
                Accept: "application/json",
            },
            type: "PUT",
            url: base_url + "/carts/" + input.data("id"),
            data: { qty: input.val() },
            dataType: "JSON",
            success: function (response) {
                $("#status_" + input.data("id")).html(
                    '<i class="bi bi-check me-2 text-success "></i><span class="text-success">' +
                        response +
                        "</span>"
                );
                setTimeout(() => {
                    $("#status_" + input.data("id")).html("");
                }, 3000);
            },
            error: function (e) {
                $("#status_" + input.data("id")).html(
                    '<i class="bi bi-x me-2 text-danger "></i><span class="text-danger">' +
                        e.responseJSON.errors.qty[0] +
                        "</span>"
                );
                setTimeout(() => {
                    location.reload();
                }, 1000);
            },
        });
    });
    if ($(".image-popup").length > 0) {
        $(".image-popup").magnificPopup({
            type: "image",
            zoom: {
                enabled: true,
                duration: 300,
                easing: "ease-in-out",
                opener: function (openerElement) {
                    return openerElement.is("img")
                        ? openerElement
                        : openerElement.find("img");
                },
            },
        });
    }
    if ($("#total_picked").length > 0) {
        try {
            const picked_place = $("#total_picked");
            let total_picked = 0;
            const pick_key = "old_pickeds_" + picked_place.data("param");
            const pick_limit = parseInt(picked_place.data("max"));
            const btn_save = $("#btn-save-picking-list");
            let picked_list = localStorage.getItem(pick_key);
            if (picked_list) {
                picked_list = JSON.parse(picked_list);
            } else {
                if (picked_place.data("old")) {
                    picked_list = picked_place.data("old");
                } else {
                    picked_list = {};
                }
            }
            picked_place.removeAttr("data-old");
            picked_place.removeAttr("data-max");
            picked_place.removeAttr("data-params");
            picked_place.removeAttr("id");
            $.each(picked_list, function (index, items) {
                if ($('input[name="' + index + '"]').length > 0) {
                    $('input[name="' + index + '"]').val(items.qty);
                }
                total_picked += parseInt(items.qty);
            });

            picked_place.text(total_picked);
            if (total_picked == pick_limit) {
                picked_place.addClass("text-success");
            } else if (total_picked > pick_limit) {
                picked_place.addClass("text-danger");
            } else {
                picked_place.addClass("text-warning");
            }
            if (total_picked <= pick_limit && total_picked > 0) {
                $("#btn-save-picking-list").removeClass("disabled");
            } else {
                $("#btn-save-picking-list").addClass("disabled");
            }
            $(".pick-qty-input").on("change", function () {
                let temp_val = $(this).val();
                if (temp_val < 0) {
                    $(this).val(0).trigger("change");
                } else if (temp_val > pick_limit) {
                    $(this).val(pick_limit).trigger("change");
                } else {
                    picked_place.removeClass("text-success");
                    picked_place.removeClass("text-danger");
                    picked_place.removeClass("text-warning");
                    total_picked = 0;
                    picked_list[$(this).attr("name")] = {
                        id: $(this).data("id") ?? null,
                        qty: temp_val,
                    };
                    localStorage.setItem(pick_key, JSON.stringify(picked_list));
                    $.each(picked_list, function (index, items) {
                        total_picked += parseInt(items.qty);
                    });
                    picked_place.text(total_picked);

                    if (total_picked == pick_limit) {
                        picked_place.addClass("text-success");
                    } else if (total_picked > pick_limit) {
                        picked_place.addClass("text-danger");
                    } else {
                        picked_place.addClass("text-warning");
                    }
                    if (total_picked <= pick_limit && total_picked > 0) {
                        $("#btn-save-picking-list").removeClass("disabled");
                    } else {
                        $("#btn-save-picking-list").addClass("disabled");
                    }
                    $("#diff_pick_qty").text(
                        Math.abs(pick_limit - total_picked)
                    );
                }
            });
            btn_save.on("click", function (e) {
                e.preventDefault();
                let message =
                    $(this).data("confirm") ?? dictionaries.confirm.save[lang];
                if (total_picked > pick_limit || total_picked == 0) {
                    return;
                } else {
                    Swal.fire({
                        icon: "question",
                        text: message,
                        showDenyButton: true,
                        confirmButtonText: dictionaries.yes[lang],
                        denyButtonText: dictionaries.no[lang],
                    }).then((result) => {
                        if (result.isConfirmed) {
                            $(".preloader").show();
                            $.ajax({
                                headers: {
                                    "X-CSRF-TOKEN": $(
                                        'meta[name="csrf-token"]'
                                    ).attr("content"),
                                    Accept: "application/json",
                                },
                                type: btn_save.data("method"),
                                url: btn_save.attr("href"),
                                data: picked_list,
                                dataType: "JSON",

                                success: function (response) {
                                    location.href = response.url;
                                },
                                error: function (e) {
                                    $(".preloader").hide();
                                    try {
                                        Swal.fire({
                                            text: e.responseJSON.errors.qty[0],
                                            icon: "error",
                                        }).then(() => {
                                            location.reload();
                                        });
                                    } catch (error) {
                                        console.log(error);
                                        onError();
                                    }
                                },
                            }).always(function () {});
                        }
                    });
                }
            });
        } catch (error) {
            console.log(error);
        }
    } else {
        localStorage.clear();
    }

    $(".btn-minus").on("click", function (e) {
        e.preventDefault();
        let target = $($(this).data("target"));
        let min = parseInt(target.attr("min"));
        let curr_val = parseInt(target.val());
        if (curr_val > min) {
            target.val(curr_val - 1).trigger("change");
        }
    });
    $(".btn-plus").on("click", function (e) {
        e.preventDefault();
        let target = $($(this).data("target"));
        let max = parseInt(target.attr("max"));
        let curr_val = parseInt(target.val());
        if (target.length > 0 && max > curr_val) {
            target.val(curr_val + 1).trigger("change");
        }
    });
    function postData(url, data, onSuccess) {
        $.ajax({
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            type: "post",
            url: url,
            data: data,
            dataType: "JSON",
            success: onSuccess,
            error: onError,
        });
    }

    function onError() {
        Swal.fire("Gagal", dictionaries.errors.connection[lang], "warning");
    }
});
