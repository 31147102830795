document.addEventListener("DOMContentLoaded", function () {
    var docManagementEl = document.getElementById("docManagement");
    if (docManagementEl) {
        const params = JSON.parse(atob($(docManagementEl).data("params")));
        console.log(params);
        $.each(params, function (index, value) {});
        var docManagement = new FullCalendar.Calendar(docManagementEl, {
            initialView: "dayGridMonth",
            themeSystem: "bootstrap5",
            timeZone: "UTC+7",
            eventMinHeight: 75,
            headerToolbar: {
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
            },
            weekNumbers: true,
            dayMaxEvents: true, // allow "more" link when too many events
            events: params,
        });
        docManagement.render();
    }
});
