$(function () {
    const body_styles = window.getComputedStyle(document.body);
    const colors = {
        primary: $.trim(body_styles.getPropertyValue("--bs-primary")),
        secondary: $.trim(body_styles.getPropertyValue("--bs-secondary")),
        info: $.trim(body_styles.getPropertyValue("--bs-info")),
        success: $.trim(body_styles.getPropertyValue("--bs-success")),
        danger: $.trim(body_styles.getPropertyValue("--bs-danger")),
        warning: $.trim(body_styles.getPropertyValue("--bs-warning")),
        light: $.trim(body_styles.getPropertyValue("--bs-light")),
        dark: $.trim(body_styles.getPropertyValue("--bs-dark")),
        blue: $.trim(body_styles.getPropertyValue("--bs-blue")),
        indigo: $.trim(body_styles.getPropertyValue("--bs-indigo")),
        purple: $.trim(body_styles.getPropertyValue("--bs-purple")),
        pink: $.trim(body_styles.getPropertyValue("--bs-pink")),
        red: $.trim(body_styles.getPropertyValue("--bs-red")),
        orange: $.trim(body_styles.getPropertyValue("--bs-orange")),
        yellow: $.trim(body_styles.getPropertyValue("--bs-yellow")),
        green: $.trim(body_styles.getPropertyValue("--bs-green")),
        teal: $.trim(body_styles.getPropertyValue("--bs-teal")),
        cyan: $.trim(body_styles.getPropertyValue("--bs-cyan")),
        chartTextColor: $("body").hasClass("dark") ? "#6c6c6c" : "#b8b8b8",
        chartBorderColor: $("body").hasClass("dark") ? "#444444" : "#ededed",
    };
    function movementStock() {
        if ($("#movement_stock").length) {
            const data = JSON.parse(atob($("#movement_stock").data("chart")));
            const options = {
                series: [
                    {
                        name: data.inbound.name,
                        data: data.inbound.data,
                    },
                    {
                        name: data.outbound.name,
                        data: data.outbound.data,
                    },
                ],
                theme: {
                    mode: $("body").hasClass("dark") ? "dark" : "light",
                },
                chart: {
                    height: 350,
                    type: "line",
                    foreColor: colors.chartTextColor,
                },
                dataLabels: {
                    enabled: false,
                },
                colors: [colors.success, colors.danger],
                stroke: {
                    width: 4,
                    curve: "smooth",
                },
                legend: {
                    show: false,
                },
                markers: {
                    size: 0,
                    hover: {
                        sizeOffset: 6,
                    },
                },
                xaxis: {
                    categories: data.months,
                },
                tooltip: {
                    y: [
                        {
                            title: {
                                formatter: function (val) {
                                    return val;
                                },
                            },
                        },
                        {
                            title: {
                                formatter: function (val) {
                                    return val;
                                },
                            },
                        },
                        {
                            title: {
                                formatter: function (val) {
                                    return val;
                                },
                            },
                        },
                    ],
                },
                grid: {
                    borderColor: colors.chartBorderColor,
                },
            };

            new ApexCharts(
                document.querySelector("#movement_stock"),
                options
            ).render();
        }
    }

    movementStock();

    function stockCategory() {
        if ($("#stock_category").length) {
            const data = JSON.parse(atob($("#stock_category").data("chart")));
            const options = {
                chart: {
                    height: 250,
                    type: "donut",
                    offsetY: 0,
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: "40%",
                        },
                    },
                },
                stroke: {
                    show: false,
                    width: 0,
                },
                colors: $.map(data.colors, function (value) {
                    return colors[value];
                }),
                series: data.values,
                labels: data.labels,
                legend: {
                    show: false,
                },
            };

            new ApexCharts(
                document.querySelector("#stock_category"),
                options
            ).render();
        }
    }
    stockCategory();

    function stockInventory() {
        if ($("#stock_inventory").length > 0) {
            const data = JSON.parse(atob($("#stock_inventory").data("chart")));
            let options = {
                series: [
                    {
                        name: data.inbound.name,
                        data: data.inbound.data,
                    },
                    {
                        name: data.outbound.name,
                        data: data.outbound.data,
                    },
                    {
                        name: data.balance.name,
                        data: data.balance.data,
                    },
                ],
                chart: {
                    type: "bar",
                    foreColor: colors.chartTextColor,
                    height: 350,
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: "55%",
                        endingShape: "rounded",
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                colors: [colors.success, colors.danger, colors.info],
                stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                },
                theme: {
                    mode: $("body").hasClass("dark") ? "dark" : "light",
                },
                xaxis: {
                    categories: data.labels,
                },

                fill: {
                    opacity: 1,
                },
                grid: {
                    borderColor: colors.chartBorderColor,
                },
            };

            new ApexCharts(
                document.querySelector("#stock_inventory"),
                options
            ).render();
        }
    }
    stockInventory();
    function warehouseCapacity() {
        if ($("#warehouse_capacity").length > 0) {
            const data = JSON.parse(
                atob($("#warehouse_capacity").data("chart"))
            );

            var options = {
                series: data.series,
                chart: {
                    height: 350,
                    type: "bar",
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    },
                },
                colors: [colors.success],
                dataLabels: {
                    formatter: function (val, opt) {
                        const goals =
                            opt.w.config.series[opt.seriesIndex].data[
                                opt.dataPointIndex
                            ].goals;

                        if (goals && goals.length) {
                            return `${val} / ${goals[0].value}`;
                        }
                        return val;
                    },
                },
                legend: {
                    show: true,
                    showForSingleSeries: true,
                    customLegendItems: data.legends,
                    markers: {
                        fillColors: [colors.success, colors.danger],
                    },
                },
            };
            new ApexCharts(
                document.querySelector("#warehouse_capacity"),
                options
            ).render();
        }
    }
    warehouseCapacity();
    if ($("#floorsChart").length > 0) {
        const chartID = "#floorsChart";
        const params = JSON.parse(atob($(chartID).data("chart")));
        let parents = [];
        let labels = [];
        let values = [];
        let index = 0;
        $.each(params, function (index0, value0) {
            parents[index] = "";
            labels[index] = "S-Loc:" + "" + (index0 + 1);
            values[index] = value0.name;
            index++;
            $.each(value0.floors, function (index1, value1) {
                parents[index] = "S-Loc:" + "" + (index0 + 1);
                labels[index] = "L:" + "" + value1.name;
                values[index] = "Lantai " + value1.name;
                index++;
                $.each(value1.cabinets, function (index2, value2) {
                    parents[index] = "L:" + "" + value1.name;
                    labels[index] =
                        "L:" + "" + value1.name + " P:" + value2.name;
                    values[index] =
                        "Peron " +
                        value2.name +
                        "<br>Kapasitas " +
                        value2.capacity;
                    index++;
                    $.each(value2.racks, function (index3, value3) {
                        parents[index] =
                            "L:" + "" + value1.name + " P:" + value2.name;
                        labels[index] =
                            "L:" +
                            "" +
                            value1.name +
                            " P:" +
                            value2.name +
                            " R:" +
                            (index3 + 1);
                        values[index] =
                            "Rak " +
                            value3.name +
                            value3.size +
                            "<br>Kapasitas " +
                            value3.capacity;
                        index++;
                        $.each(value3.boxes, function (index4, value4) {
                            parents[index] =
                                "L:" +
                                "" +
                                value1.name +
                                " P:" +
                                value2.name +
                                " R:" +
                                (index3 + 1);
                            labels[index] =
                                "L:" +
                                "" +
                                value1.name +
                                " P:" +
                                value2.name +
                                " R:" +
                                (index3 + 1) +
                                " B:" +
                                value4.name;

                            values[index] =
                                "Box " +
                                value4.name +
                                "<br>Kapasitas " +
                                value4.capacity;
                            index++;
                        });
                    });
                });
            });
        });
        data = [
            {
                text: values,
                type: "treemap",
                labels: labels,
                // values: values,
                parents: parents,
               
            },
        ];
        var layout = {
            autosize: true,
            height: 800,
        };
        Plotly.newPlot("floorsChart", data, layout);
    }
});
